import { ListGrid } from 'cheetah-grid';
import { CellDefine, HeaderCellDefine } from 'cheetah-grid/list-grid/layout-map/api';
import { Moment } from 'moment';
import tokenActions from '../../actions/tokenActions';
import { TableRecord } from '../canvas_table/components/TableRecord';
import { TableMode } from './table_body/table_body';
import { ProjectError, ProjectSimpleError } from '../../ApiEpo';

export interface CellOpen {
  record: TableRecord;
  col: number;
  row: number;
  cell: any;
  columnKey: string;
  isModalAdd: boolean;
  isModalApproveBudget: boolean;
  isModalContragent: boolean;
  isModalSendApprove: boolean;
  isModalInvoice: boolean;
  isModalWayBill: boolean;
  isModalBill: boolean;
  isModalSettings: boolean;
  isModalHistory: boolean;
  openEdit: boolean;
  onCancelEdit?: (value: string) => void;
  timeOpen?: Moment;
}

export function getExcludesColumns() {
  const res: (keyof TableRecord)[] = [];

  if (tokenActions.budgetPlan == 'NONE') {
    res.push(
      'cns_budget_plan_size',
      'cns_budget_plan_price',
      'cns_budget_plan_sum_wat',
      'cns_budget_plan_far',
      'cns_budget_plan_far2',
    );
  }

  if (tokenActions.budgetFact == 'NONE') {
    res.push('cns_budget_fakt_size', 'cns_budget_fakt_price', 'cns_budget_fakt_sum_wat', 'cns_budget_fakt_far');
  }

  if (tokenActions.planeDates == 'NONE') {
    res.push('cns_plane_date_start', 'cns_plane_date_end');
  }

  if (tokenActions.contracts == 'NONE' && tokenActions.contractsDates == 'NONE') {
    res.push('cns_contact_date', 'cns_contracts');
  }

  return res;
}

export interface WData {
  rows: TableRecord[];
  rows2: TableRecord[];
  rows3: TableRecord[];
  resultRows: TableRecord[];
  errors: ProjectError[];
  errorsSimple: ProjectSimpleError[];
  errorsTableView: { [x: string]: { [x: string]: string } };
  grid: ListGrid<TableRecord>;
  isInit: boolean;
  expandedLevel1: string[];
  expandedLevel2: string[];
  cellOpen?: CellOpen;
  moveCell?: { col: number; row: number; record: TableRecord; event?: any };
  excludedColumns: string[];
  resultRowValues: { [x: string]: string };
  tableMode: TableMode;
  textInput?: string;
  setRefresh: (val: number) => void;
  loadDataPassport: (params: { showLoaded: boolean }) => Promise<void>;
  body: CellDefine<TableRecord>[][];
  dedlainDays: number;
  hasAllowMoveOfApprove: boolean;
  t: (key: string) => string;
  struct: {
    getTableHeaders: () => HeaderCellDefine<TableRecord>[][];
    getTableBody: (mode: TableMode, headers: CellDefine<TableRecord>[][]) => CellDefine<TableRecord>[][];
  };
  refresh: {
    firstCalc: () => void;
    refreshStatusRecords: () => void;
    refreshFar: () => void;
  };
  globalActions: {
    isModalErrors: boolean;
    isModalAddManyContracts: boolean;
    isModalAddInvoice: boolean;
    isModalAddWayBills: boolean;
    isModalAddBills: boolean;
  };
}

export function getWData(): WData {
  return (
    (window as any).canvasT ?? {
      rows: [],
      rows2: [],
      rows3: [],
      resultRows: [],
      isInit: false,
      expandedLevel1: [],
      expandedLevel2: [],
      cellOpen: undefined,
      excludedColumns: getExcludesColumns(),
      hasAllowMoveOfApprove: false,
      // [
      // "cns_title",
      // "cns_ed_izm",
      // "cns_budget_mark_sum_wat",
      // "cns_budget_plan_size",
      // "cns_budget_plan_price",
      // "cns_budget_plan_sum_wat",
      // "cns_budget_plan_far",
      // "cns_budget_plan_far2",
      // "cns_budget_fakt_size",
      // "cns_budget_fakt_price",
      // "cns_budget_fakt_sum_wat",
      // "cns_budget_fakt_far",
      // "cns_contact_date",
      // "cns_contact_contragent",
      // "cns_cantact_contragent",
      // ],
      resultRowValues: {
        cns_budget_mark_sum_wat: '',
        cns_budget_plan_sum_wat: '',
        cns_budget_plan_far2: '',
        cns_budget_fakt_size: '',
      },
      tableMode: 'view',
      textInput: undefined,
      setRefresh: (val) => {
      },
      loadDataPassport: () => Promise.resolve(),
      refreshStatusRecords: () => {
      },
      body: [],
      dedlainDays: 0,
      t: (key) => key,
      globalActions: {
        isModalErrors: false,
        isModalAddManyContracts: false,
        isModalAddInvoice: false,
        isModalAddWayBills: false,
        isModalAddBills: false,
      },
    }
  );
}
