import { HeaderCellDefine } from 'cheetah-grid/list-grid/layout-map/api';
import { TableRecord } from '../../canvas_table/components/TableRecord';
import { getColumnHeaderStyle } from './column_header_style';
import {localeKeys} from "../../../i18n/localeKeys";

const t = () => (window as any).t;

export const headersMain: () => HeaderCellDefine<TableRecord>[] = () => [
  {
    caption: '№',
    width: 85,
    headerStyle: {
      ...getColumnHeaderStyle('cns_number'),
    },
    rowSpan: 3,
  },
  {
    caption: t()?.(localeKeys.table.columns.name),
    headerStyle: getColumnHeaderStyle('cns_title'),
    rowSpan: 3,
    colSpan: 2,
  },
  {
    caption: t()?.(localeKeys.table.columns.unit),
    width: 85,
    headerStyle: getColumnHeaderStyle('cns_ed_izm'),
    rowSpan: 3,
  },
];

export const headersMain2: () => HeaderCellDefine<TableRecord>[] = () => [];
