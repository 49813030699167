import {
  BaseStyleOption,
  BranchGraphStyleOption,
  ButtonStyleOption,
  CheckStyleOption,
  ColumnStyle,
  IconStyleOption,
  ImageStyleOption,
  MenuStyleOption,
  MultilineTextStyleOption,
  NumberStyleOption,
  PercentCompleteBarStyleOption,
  StdBaseStyleOption,
  StyleOption,
} from 'cheetah-grid/ts-types/column/style';
import {RecordContractStatus, RecordStatus, TableRecord} from '../../canvas_table/components/TableRecord';
import allowEditCell, {
  allowViewBudgetFactCell,
  allowViewBudgetPlanCell,
  allowViewPlannedDatesCell,
} from '../allowEditCell';
import {getBgCellBudgetSumWat, getBgCellContracts, getBgCellContractsStatus} from '../getCellBg';
import { numbersColumns } from '../getTableColumnKey';
import { getWData } from '../getWData';
import { getPassportCache } from '../loadData';
import { themeTable } from '../table_theme';
import { groupsColumnsBF, groupsColumnsBP, groupsColumnsContracts } from './groups_columns';
import {ErrorTypeEnum} from "../../canvas_table/components/utils/error_type";

export type CellStyle =
  | string
  | ColumnStyle
  | BaseStyleOption
  | StdBaseStyleOption
  | StyleOption
  | ButtonStyleOption
  | CheckStyleOption
  | NumberStyleOption
  | MultilineTextStyleOption
  | MenuStyleOption
  | ImageStyleOption
  | IconStyleOption
  | BranchGraphStyleOption
  | PercentCompleteBarStyleOption;

export function getBodyCellStyle(
  rec: TableRecord,
  append?: { bold?: boolean; allowEdit?: boolean; field: keyof TableRecord },
): CellStyle {
  // console.log({rec, append});
  let bgColor = themeTable.bgColor;
  const tableRec = rec;
  if (allowEditCell(rec, append?.field ?? 'cns_title')) {
    bgColor = themeTable.bgColorEdit;
  } else {
    switch (rec.cns_row_type) {
      case 'project':
        bgColor = themeTable.bgColor3;
        break;
      case 'section':
        bgColor = themeTable.bgColor;
        break;
      case 'work_type':
        bgColor = themeTable.bgColor2;
        break;
      case 'nomenclature':
        bgColor = themeTable.bgColor3;
        break;
    }
  }

  let font = rec.cns_row_type == 'nomenclature' ? themeTable.font : themeTable.fontMedium;
  let fontColor = themeTable.fontColor;

  if (rec.cns_row_type == 'project') {
    font = themeTable.fontHeaderBold;
  }

  let res: CellStyle = {
    bgColor: bgColor,
    font: font,
    color: fontColor,
    textAlign: numbersColumns.includes(append?.field ?? 'cns_title') ? 'right' : 'left',
    padding: [0, 8, 0, 8],
  };

  if (append?.field === 'cns_budget_plan_sum_wat' || append?.field === 'cns_budget_plan_sum_wat2') {
    (res as any).bgColor = getBgCellBudgetSumWat(rec);
  }


  // if (append?.field === 'cns_contact_date' || append?.field === 'cns_contracts') {
  //   // (res as any).bgColor = getBgCellContracts(rec) ?? (res as any).bgColor;
  // }

  if (
    ((append?.field && getWData().excludedColumns.includes(append!.field)) || rec.cns_level <= 0) &&
    (!allowViewBudgetFactCell(append!.field) ||
      !allowViewBudgetPlanCell(append!.field, rec) ||
      !allowViewPlannedDatesCell(append!.field))
  ) {
    res.color = themeTable.transparent;
    res.bgColor = themeTable.bgColor3;
    (res as any).borderColor = themeTable.transparent;
  }

  if (
    rec.cns_row_type == 'project' &&
    !getWData().rows3.find((e) => e.cns_status == RecordStatus.BUDGET_PLAN_APPROVED) &&
    [...groupsColumnsBF, ...groupsColumnsContracts].includes(append?.field ?? '')
  ) {
    res.color = themeTable.transparent;
    res.bgColor = themeTable.transparent;
    (res as any).borderColor = themeTable.transparent;
  }

  if (
    !getWData().rows3.find((e) => e.cns_status == RecordStatus.BUDGET_PLAN_APPROVED) &&
    ['cns_plane_size'].includes(append?.field ?? '')
  ) {
    res.color = themeTable.transparent;
    res.bgColor = themeTable.transparent;
    (res as any).borderColor = themeTable.transparent;
  }

  if (getWData().excludedColumns.includes(append?.field ?? '') || rec.cns_row_type == '') {
    res.color = themeTable.transparent;
    res.bgColor = themeTable.transparent;
    (res as any).borderColor = themeTable.transparent;
  }

  let id =  rec.cns_nomenclature_id

  switch(rec.cns_row_type) {
    case 'nomenclature':
      id = rec.cns_nomenclature_id
      break
    case 'section':
      id = rec.cns_section_id
      break
    case 'work_type':
      id = rec.cns_group_id
      break
  }

  const errorStatus = getWData().errorsTableView[id ?? '']?.[append?.field ?? 'cns_title']

  if (errorStatus == 'late' || errorStatus == 'open') {
    res.bgColor = themeTable.bgColorError;
  }
  if (errorStatus == 'closed') {
    res.bgColor = themeTable.bgColorWarning;
  }
  // && getBgCellContractsStatus((res as any)) getWData().rows3.some((e) => e.cns_contracts.length > 0 &&
  //     e.cns_contracts.some((it)=>it.status !== RecordContractStatus.SIGNET))
  if (append?.field === 'cns_contact_date') {
    const errorsByNom = getWData().errors.filter(error => error.nomenclature_id == rec.cns_nomenclature_id && error.type == ErrorTypeEnum.CONTRACT_PLANNING_DATE_ERROR)
    const errorsNotClosed = errorsByNom.find(error => !!error.closed_at)

    if (!errorsNotClosed) {
      const errorsClosed = errorsByNom.find(error => !error.closed_at)

      if (errorsClosed) {
        res.bgColor = themeTable.bgColorEdit;
        res.color = themeTable.bgColorError;
      }
    }
  }

  // //TODO Контракты на согласовании - серый шрифт;
  if (append?.field && tableRec.cns_row_type === 'nomenclature' && getBgCellContractsStatus(tableRec)) {
      switch(append?.field) {
        case 'cns_well_bill_delivery_sum':
          if(Number(tableRec.cns_well_bill_delivery_sum)) res.color = themeTable.bgColor4; //  themeTable.bgColor4;
          break
      }
  }

  return res;
}
