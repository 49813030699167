import { MenuOutlined, PlusOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Drawer, Dropdown, MenuProps } from 'antd';
import { FunctionComponent, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import tokenActions, { AccessItem } from '../actions/tokenActions';
import { usePassportList } from '../actions/usePassportList';
import { sizes } from './sizes';
import { BreadcrumbItemType } from 'antd/es/breadcrumb/Breadcrumb';
import { useTranslation } from 'react-i18next';
import { localeKeys } from '../i18n/localeKeys';
import { LoadData } from '../screens/table_extends/loadData';

const HeaderComponent: FunctionComponent<{
    title: string;
    onAction: (type: string, payload: any) => void;
    selectProjects?: boolean;
}> = (props) => {
    const route = window.location.pathname;
    const { passports } = usePassportList({ status: 'active' });
    const { t } = useTranslation();
    const [countErrors, setCountErrors] = useState(0);

    useEffect(() => {
        setCountErrors(0);
        (window as any).onLoadedPassport = (res: LoadData) => {
            setCountErrors(res?.errorsSimple?.filter(e => e.is_hidden != true)?.filter(e => !e.closed_at)?.length ?? 0);
        };
    }, []);

    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const scrollSize = window.outerWidth < sizes.tablet ? 0 : 55;
    const isMobile = window.outerWidth < sizes.tablet;

    const canvasCreateItems: MenuProps['items'] = [
        tokenActions.contracts === AccessItem.EDIT ? {
            key: 'add_many_contracts',
            label: t(localeKeys.header.addContract),
            onClick: () => props.onAction && props.onAction('add_many_contracts', {}),
        } : null,
        tokenActions.invoices === AccessItem.EDIT ? {
            key: 'add_invoices',
            label: t(localeKeys.header.addInvoice),
            onClick: () => props.onAction && props.onAction('add_invoices', {}),
        } : null,
        tokenActions.wellBill === AccessItem.EDIT ? {
            key: 'add_way_bills',
            label: t(localeKeys.header.addWaybill),
            onClick: () => props.onAction && props.onAction('add_way_bills', {}),
        } : null,
        tokenActions.bill === AccessItem.EDIT ? {
            key: 'add_bills',
            label: t(localeKeys.header.addBill),
            onClick: () => props.onAction && props.onAction('add_bills', {}),
        } : null,
    ].filter(e => e);

    const btnErrors = <Button
        className={'btn_show_errors'}
        data-test={'SHOW_ERRORS'}
        type="link"
        onClick={() => props.onAction && props.onAction('show_errors', {})}
    >
        <img className="icon_notification" src="/canvas_table/icon_warning.svg"
             alt={t(localeKeys.header.notifications)} />
        { !!countErrors && <div className={'header_component__error_count'}>{countErrors}</div>}
    </Button>


    const btnCanvas = <div className={'header_component__canvas-btns'}>
        {canvasCreateItems.length ? <Dropdown menu={{ items: canvasCreateItems }} rootClassName={'header_component__dropdown'}>
        <a className={'header_component__btn_add'} onClick={(e) => e.preventDefault()}>
            <PlusOutlined /> &nbsp;
            {t(localeKeys.header.addBtn)} &nbsp;
        </a>
    </Dropdown> : <></>}
        {!isMobile && <>{btnErrors}</>}
    </div>;

    const rightPath = (
        <div className="header_component_right">
            {route.includes('/passport') && !isMobile && btnCanvas}
            {tokenActions.createProject == AccessItem.ALLOW && route === '/' && (
                <Button
                    className={'btn_create_project'}
                    data-test={'CREATE_PROJECT'}
                    type="link"
                    onClick={() => props.onAction && props.onAction('create_project', {})}
                >
                    <PlusOutlined /> {t(localeKeys.passportListScreen.addPassport)}
                </Button>
            )}
            {tokenActions.contragents == AccessItem.EDIT && route == '/contragents' && (
                <Button
                    className={'btn_create_project'}
                    data-test={'CREATE_CONTRAGENT'}
                    type="link"
                    onClick={() => props.onAction && props.onAction('create_contragent', {})}
                >
                    <PlusOutlined /> {t(localeKeys.header.addContragent)}
                </Button>
            )}
            {route.includes('/passport') && isMobile && btnErrors}
            <Link to={'/notifications'} className={route === '/notifications' ? 'active' : ''}>
                <img className="icon_notification" src="/header/notification.svg"
                     alt={t(localeKeys.header.notifications)} />
            </Link>
            <Link to={'/profile'} className={route === '/profile' ? 'active' : ''}>
                <img className="icon_profile" src="/header/profile.svg" alt={t(localeKeys.header.profile)} />
            </Link>
        </div>
    );

    const iconMenu = (
        <div className={'header_component__menu_icon'} onClick={showDrawer}>
            <MenuOutlined style={{ fontSize: '28px', position: 'relative', top: '1.5px' }} />
        </div>
    );

    const drawer = (
        <Drawer
            className={'header_component__drawer'}
            title={<img className={'header_component__drawer_logo'} src={'/header/logo_header.svg'} alt={'logo'} />}
            onClose={onClose}
            open={open}
            placement={'left'}
        >
            <div className={'header_component__drawer_menu'}>
                {tokenActions.listProjects != AccessItem.NONE && <Link to={'/'}>{t(localeKeys.header.projects)}</Link>}
                {tokenActions.contragents != AccessItem.NONE &&
                    <Link to={'/contragents'}>{t(localeKeys.header.suppliers)}</Link>}
                {tokenActions.finContr != AccessItem.NONE &&
                    <Link to={'/fin-controller'}>{t(localeKeys.header.financialController)}</Link>}
                <Link to={'/contract-negotiation'}>{t(localeKeys.header.contractNegotiation)}</Link>
            </div>
        </Drawer>
    );

    const items =
        passports?.data?.map((e) => ({
            key: e.id,
            label: <a href={`/passport/${e.id}`}> {e.title} </a>,
        })) ?? [];

    const breadcrumbs: BreadcrumbItemType[] = [
        { title: props.title, menu: props.selectProjects ? { items } : undefined },
    ];

    if (window.outerWidth > sizes.mobile) {
        breadcrumbs.unshift({
                title: <>{window.outerWidth > sizes.mobile &&
                    <img src="/header/logo_header.svg" alt="/" style={{ position: 'relative', top: 3 }} />}</>,
                href: tokenActions.listProjects != AccessItem.NONE ? '/' : '/contragents',
            },
        );
    }

    return (<>
        <div
            className={`header_component ${route == '/contragents' ? 'header_component__contragents' : ''}`}
            style={{ maxWidth: window.outerWidth - scrollSize, display: 'var(--visibleHeader)' }}
        >
            {iconMenu}
            <Breadcrumb
                items={breadcrumbs}
                separator={<img src="/header/breadcrumb_dot.svg" alt="/" />}
            ></Breadcrumb>
            {rightPath}

            {drawer}
        </div>
        {route.includes('/passport') && window.outerWidth <= sizes.mobile && btnCanvas}
    </>);
};

export default HeaderComponent;

export function getHeaderComponentHeight() {
    return document.querySelector('.header_component')?.getBoundingClientRect().height ?? 0;
}
