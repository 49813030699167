import {ContractorShort} from "./ApiDirectory";
import * as idb from 'idb'
import {DBSchema} from 'idb'
import {api, directoryApi, documentsApi} from "./shared/api_client";
import {message} from "antd";
import {base642file, uploadFileAsync} from "./screens/contragents/uploadFile";
import {Project, ProjectDetail, User} from "./ApiEpo";
import {getPassportCache, LoadData} from "./screens/table_extends/loadData";
import {TableRecord, TableRecordContragent} from "./screens/canvas_table/components/TableRecord";
import {IApiContragent, IApiContragent2} from "./Models/ContragentForm";
import {IApiInvoice, IApiInvoice2} from "./Models/InvoiceForm";
import {IApiWaybill2} from "./Models/WayBillsForm";
import {IApiBills2} from "./Models/BillsForm";
import {syncData} from "./screens/table_extends/syncData";
import {getWData} from "./screens/table_extends/getWData";
import moment from 'moment';

export enum DocumentType {
  contract = 'contract',
  invoice = 'invoice',
  bill = 'bill',
  waybill = 'waybill',
}

export interface DocumentDb {
  id: string,
  type: DocumentType,
  item: IApiContragent2 | IApiInvoice2,
  error_action?: boolean,
  passport_id: string
  created_at?: string
  updated_at?: string
}

interface ContragentsDb extends DBSchema {
  contragents: {
    value: ContractorShort & { error_action?: boolean },
    key: string;
    indexes: { row_number: number, id: string };
  },
  contragents__add: {
    value: ContractorShort & { error_action?: boolean, created_at: string }
    key: string;
    indexes: { id: string };
  },
  contragents__delete: {
    value: ContractorShort,
    key: string;
    indexes: { id: string };
  },
  contragents__update: {
    value: ContractorShort & { error_action?: boolean, updated_at: string }
    key: string;
    indexes: { id: string };
  },
  filters: {
    value: {
      key: string,
      items: { value: string, label: string }[]
    },
    key: string;
    indexes: { key: string };
  },
  contractors_locations: {
    value: { key: string, data: string[] },
    key: string;
  },
  project_locations: {
    value: { key: string, data: string[] },
    key: string;
  },
  projects_list: {
    value: Project,
    key: string;
    indexes: { id: string };
  },
  projects_list_archive: {
    value: Project,
    key: string;
    indexes: { id: string };
  },
  project_detail: {
    value: ProjectDetail,
    key: string;
    indexes: { id: string };
  },
  project_table: {
    value: LoadData,
    key: string;
    indexes: { id: string };
  },
  project_documents__add: {
    value: DocumentDb,
    key: string;
    indexes: { id: string };
  }
}

export let db: idb.IDBPDatabase<ContragentsDb> | null | undefined = undefined;

export async function requestDatabase(id: string) {
  if (db !== undefined) return;
  db = null;
  db = await idb.openDB('contragents', 6, {

    upgrade(db, oldVersion, newVersion, transaction, event) {
      console.log('База данных обновляет структуру', oldVersion, newVersion, transaction, event)
      if (oldVersion < 1) {
        db.createObjectStore('contragents', {keyPath: 'id'}).createIndex('row_number', 'row_number')
        db.createObjectStore('contragents__add', {keyPath: 'id'}).createIndex('id', 'id')
        db.createObjectStore('contragents__delete', {keyPath: 'id'}).createIndex('id', 'id')
        db.createObjectStore('contragents__update', {keyPath: 'id'}).createIndex('id', 'id')
      }
      if (oldVersion < 2) {
        db.createObjectStore('filters', {keyPath: 'key'})
      }
      if (oldVersion < 3) {
        db.createObjectStore('contractors_locations', {keyPath: 'key'})
        db.createObjectStore('project_locations', {keyPath: 'key'})
      }

      if (oldVersion < 4) {
        db.createObjectStore('projects_list', {keyPath: 'id'}).createIndex('id', 'id')
        db.createObjectStore('projects_list_archive', {keyPath: 'id'}).createIndex('id', 'id')
      }

      if (oldVersion < 5) {
        db.createObjectStore('project_detail', {keyPath: 'id'}).createIndex('id', 'id')
        db.createObjectStore('project_table', {keyPath: 'id'}).createIndex('id', 'id')
      }

      if (oldVersion < 6) {
        db.createObjectStore('project_documents__add', {keyPath: 'id'}).createIndex('id', 'id')
      }
    },
    terminated() {
      console.log("База данных закрыта");
    },
    blocked(currentVersion, blockedVersion, event) {
      console.log("База данных заблокирована");
    },
    blocking(currentVersion, blockedVersion, event) {
      console.log("База данных блокируется");
    }
  });

  window.addEventListener("offline", (e) => {
    console.log("offline");
    isOnline = false;
  });

  window.addEventListener("online", async (e) => {
    console.log("online");
    isOnline = true;
    await getWData().loadDataPassport({showLoaded: false})
    setTimeout(async () => {
      await syncData.connect(id);
      await doRunContragent();
      await doRunDocuments();
    }, 1000)
  });
}

export async function syncContragents(items: ContractorShort[]) {
  if (!db) {
    console.log("База данных не открыта");
    return;
  }

  let transaction = db.transaction('contragents', "readwrite"); // (1)

  // получить хранилище объектов для работы с ним
  let contractors = transaction.objectStore('contragents'); // (2)

  contractors.clear();

  for (const itemsKey in items) {
    try {
      if ((await contractors.get(items[itemsKey].id ?? '')) == null) {
        let request = await contractors.add(items[itemsKey]); // (3)
        console.log("Запись добавлена в хранилище", request);
      } else {
        let request = await contractors.put(items[itemsKey]); // (3)
        // console.log("Запись обновлена в хранилище", request);
      }
    } catch (e) {
      console.log(e)
    }
  }


  transaction.oncomplete = function () {
    console.log("Транзакция выполнена");
  };

  transaction.onerror = function () {
    console.log("Транзакция прервана");
  };

  transaction.onabort = function () {
    console.log("Транзакция прервана");
  };
}

interface GetContractorsDb {
  page?: number;
  perPage?: number;
  search?: string;
  tags?: string[];
  locations?: string[];
}

export async function getContractorsDb({
                                         page = 1,
                                         perPage = 10,
                                         search = '',
                                         tags = [],
                                         locations = []
                                       }: GetContractorsDb): Promise<ContractorShort[]> {
  let result: ContractorShort[] = []

  if (!db) {
    console.log("База данных не открыта");
    return [];
  }

  let cursor = await db.transaction('contragents', "readonly").store.index('row_number').openCursor(undefined, 'prev');

  let isDoOffset = false;

  while (cursor) {
    if (!isDoOffset && page > 1) {
      isDoOffset = true;
      cursor = await cursor.advance((page - 1) * perPage);
    } else {
      let flag = true;
      if (search) {
        if (!cursor.value.name!.toLowerCase().includes(search.toLowerCase()) && !cursor.value.inn!.startsWith(search.toLowerCase())) {
          flag = false;
        }
      }

      if (tags.length > 0) {
        if (!cursor.value.tags?.some((item) => tags.includes(item))) {
          flag = false;
        }
      }

      if (locations.length > 0) {
        if (!cursor.value.location?.some((item) => locations.includes(item))) {
          flag = false;
        }
      }

      if (flag) {
        result.push(cursor.value)
      }

      if (result.length < perPage) {
        cursor = await cursor.continue();
      } else {
        console.log("Собрано достаточное количество записей");
        break;
      }
    }
  }

  return result;
}

export async function getCountContractorsDb({
                                              search = '',
                                              tags = [],
                                              locations = []
                                            }: GetContractorsDb): Promise<number> {
  if (!db) {
    console.log("База данных не открыта");
    return 0;
  }

  return getContractorsDb({search, perPage: 100000, tags, locations}).then((res) => res.length)
}

export async function syncFiltersDb(filters: { key: string, items: { value: string, label: string }[] }) {
  if (!db) {
    console.log("База данных не открыта");
    return;
  }

  let transaction = db.transaction('filters', "readwrite"); // (1)

  // получить хранилище объектов для работы с ним
  let filtersStore = transaction.objectStore('filters'); // (2)

  if ((await filtersStore.get(filters.key)) != null) {
    await filtersStore.delete(filters.key); // (3)
  }

  let request = await filtersStore.put(filters); // (3)
  console.log("Запись добавлена в хранилище", request);
}

export async function syncProjectLocations(values: string[]) {
  if (!db) {
    console.log("База данных не открыта");
    return;
  }

  await updateRecord('project_locations', {data: values, key: 'project_locations'})
}

export async function syncProjectsListDb(projectsList: Project[]) {
  if (!db) {
    console.log("База данных не открыта");
    return;
  }

  for (const itemsKey in projectsList) {
    await updateRecord('projects_list', projectsList[itemsKey])
  }
}

export async function syncProjectsArchiveListDb(projectsList: Project[]) {
  if (!db) {
    console.log("База данных не открыта");
    return;
  }

  for (const itemsKey in projectsList) {
    await updateRecord('projects_list_archive', projectsList[itemsKey])
  }
}

export async function getProjectLocationsDb(): Promise<string[]> {
  if (!db) {
    console.log("База данных не открыта");
    return [];
  }

  return (await getAllRecords('project_locations') as any)?.data ?? [];
}

export async function syncContractorsLocations(values: string[]) {
  if (!db) {
    console.log("База данных не открыта");
    return;
  }

  await updateRecord('contractors_locations', {data: values, key: 'contractors_locations'})
}

export async function getContractorsLocationsDb(): Promise<string[]> {
  if (!db) {
    console.log("База данных не открыта");
    return [];
  }

  return (await getAllRecords('contractors_locations') as any)?.data ?? []
}

export async function getFiltersDb(key: string): Promise<{ value: string, label: string }[]> {
  if (!db) {
    console.log("База данных не открыта");
    return [];
  }

  // получить хранилище объектов для работы с ним
  let filters = db.transaction('filters', "readonly").objectStore('filters');

  return (await filters.get(key))?.items ?? [];
}

export async function getContragentsMaxRowNumber() {
  if (!db) {
    console.log("База данных не открыта");
    return 0;
  }

  let transaction = db.transaction('contragents', "readonly"); // (1)
  let store = transaction.objectStore('contragents'); // (2)
  let request = await store.index('row_number').openCursor(undefined, 'prev'); // (3)
  return request?.value?.row_number ?? 0
}

export async function addContragent(contragent: ContractorShort, append?: { isUpdate: boolean }) {
  if (!db) {
    console.log("База данных не открыта");
    return;
  }

  // add to contragents
  const countRows = (await getContragentsMaxRowNumber()) + 1;

  if (!append?.isUpdate) {
    await addRecord("contragents__add", {...contragent, row_number: countRows, created_at: moment().format('YYYY-MM-DDTHH:mm:ss')});
    await addRecord("contragents", {...contragent, row_number: countRows});
  } else {
    const record = await getRecord("contragents__add", contragent.id ?? '');
    await updateRecord("contragents", {...contragent, row_number: record.row_number ?? 0});
    await updateRecord("contragents__add", {...contragent, row_number: record.row_number ?? 0, created_at: moment().format('YYYY-MM-DDTHH:mm:ss')});
  }
}

export async function updateContragent(contragent: ContractorShort, onlyLocal: boolean = false) {
  if (!db) {
    console.log("База данных не открыта");
    return;
  }

  const res = {...contragent, updated_at: moment().format('YYYY-MM-DDTHH:mm:ss')};
  await updateRecord("contragents", res);
  if (!onlyLocal) {
    await updateRecord("contragents__update", res);
  }

}

export async function removeContragents(contragent: ContractorShort) {
  if (!db) {
    console.log("База данных не открыта");
    return;
  }

  let transaction = db.transaction('contragents', "readwrite"); // (1)
  let store = transaction.objectStore('contragents'); // (2)
  let request = await store.delete(contragent.id ?? ''); // (3)
  console.log("Запись удалена из хранилища", request);

  if (!contragent.id?.includes('.')) {
    let transaction2 = db.transaction('contragents__delete', "readwrite"); // (1)
    let store2 = transaction2.objectStore('contragents__delete'); // (2)
    let request2 = await store2.add(contragent); // (3)
    console.log("Запись добавлена в хранилище", request2);
  }

  let transaction3 = db.transaction('contragents__add', "readwrite"); // (1)
  let store3 = transaction3.objectStore('contragents__add'); // (2)
  if (await store3.get(contragent.id ?? '') != null) {
    let request3 = await store3.delete(contragent.id ?? ''); // (3)
    console.log("Запись удалена из хранилища", request3);
  }
}

export async function doRunContragent() {
  try {
    if (!db) {
      console.log("База данных не открыта");
      return;
    }

    const itemsCreated = await db.getAll('contragents__add');
    const itemsUpdated = await db.getAll('contragents__update');
    const itemsDeleted = await db.getAll('contragents__delete');

    let hasErrorCreated = false;
    let hasErrorUpdated = false;
    let hasErrorDeleted = false;
    let hasRemovedTimeLeft = false;

    for (let i = itemsCreated.length - 1; i >= 0; i--) {
      const itemCreated = itemsCreated[i];
      if ( itemCreated.error_action && itemCreated.created_at) {
        const isTimeLeft = moment().diff(moment(itemCreated.created_at, 'YYYY-MM-DDTHH:mm:ss')) > 24 * 60 * 1000;

        if (isTimeLeft) {
          await removeRecord('contragents', itemCreated.id ?? '');
          await removeRecord('contragents__add', itemCreated.id ?? '');
          itemsCreated.splice(i, 1);

          hasRemovedTimeLeft = true;
        }
      }
    }

    for (let i = itemsUpdated.length - 1; i >= 0; i--) {
      const itemUpdate = itemsUpdated[i];
      if (itemUpdate.error_action && itemUpdate.updated_at) {
        const isTimeLeft = moment().diff(moment(itemUpdate.updated_at, 'YYYY-MM-DDTHH:mm:ss')) > 24 * 60 * 1000;

        if (isTimeLeft) {
          await removeRecord('contragents__update', itemUpdate.id ?? '');
          itemsUpdated.splice(i, 1);

          hasRemovedTimeLeft = true;
        }
      }
    }

    if (itemsCreated.length == 0 && itemsUpdated.length == 0 && itemsDeleted.length == 0) {
      return;
    }

    for (const itemCreated of itemsCreated) {
      try {
        for (let indexDoc in itemCreated.documents ?? []) {
          let doc = itemCreated.documents![indexDoc];

          if ((doc as any).file_id?.includes('base64')) {
            const res = await uploadFileAsync(base642file((doc as any).file_id, doc.name!))
            itemCreated.documents![indexDoc] = {file_id: res.id} as any;
          }
        }


        await directoryApi.contractors.contractorsCreate({
          name: itemCreated.name ?? '',
          inn: itemCreated.inn ?? '',
          rating: itemCreated.rating as any,
          contacts: itemCreated.contacts ?? '',
          comment: itemCreated.comment ?? '',
          doc_date: itemCreated.doc_date ?? '',
          email: itemCreated.email ?? '',
          location: itemCreated.location ?? [],
          legal_address: itemCreated.legal_address ?? '',
          ogrn: itemCreated.ogrn ?? '',
          phone: itemCreated.phone ?? '',
          postal_address: itemCreated.postal_address ?? '',
          related_user: itemCreated.related_user ?? '',
          type_id: itemCreated.type?.id,
          tags: itemCreated.tags ?? [],
          requisites: itemCreated.requisites ?? [],
          doc_number: itemCreated.doc_number ?? '',
          documents: itemCreated.documents as any,
        });

        await new Promise((resolve) => setTimeout(resolve, 1000));

        await removeRecord('contragents', itemCreated.id ?? '');
        await removeRecord('contragents__add', itemCreated.id ?? '');
      } catch (e) {
        console.log(e);
        message.error((e as any)?.error?.message ?? "Сохранение поставщика завершено с ошибками");
        await updateRecord('contragents', {...itemCreated, error_action: true});
        await updateRecord('contragents__add', {...itemCreated, error_action: true});
        hasErrorCreated = true;
      }
    }

    for (const itemUpdated of itemsUpdated) {
      try {
        const itemUpdatedClone = JSON.parse(JSON.stringify(itemUpdated));
        const docsRes = [];
        for (let indexDoc in itemUpdated.documents ?? []) {
          let doc = itemUpdated.documents![indexDoc];
          if ((doc as any).file_id?.includes('base64')) {
            const res = await uploadFileAsync(base642file((doc as any).file_id, doc.name!))
            docsRes.push({file_id: res.id, remove: res.remove})
          } else {
            if ((doc as any).remove) {
              docsRes.push({id: doc.id, remove: true})
            }
          }
        }

        itemUpdated.documents = docsRes as any;

        await directoryApi.contractors.contractorsUpdate(itemUpdated.id ?? '',
          {
            name: itemUpdated.name ?? '',
            inn: itemUpdated.inn ?? '',
            rating: itemUpdated.rating as any,
            contacts: itemUpdated.contacts ?? '',
            comment: itemUpdated.comment ?? '',
            doc_date: itemUpdated.doc_date ?? '',
            email: itemUpdated.email ?? '',
            location: itemUpdated.location ?? [],
            legal_address: itemUpdated.legal_address ?? '',
            ogrn: itemUpdated.ogrn ?? '',
            phone: itemUpdated.phone ?? '',
            postal_address: itemUpdated.postal_address ?? '',
            related_user: itemUpdated.related_user ?? '',
            type_id: itemUpdated.type?.id ?? (itemUpdated as any).type_id,
            tags: itemUpdated.tags ?? [],
            requisites: itemUpdated.requisites ?? [],
            doc_number: itemUpdated.doc_number ?? '',
            documents: itemUpdated.documents as any,
          });

        await updateRecord('contragents', itemUpdatedClone);
        await removeRecord('contragents__update', itemUpdated.id ?? '');

        await new Promise((resolve) => setTimeout(resolve, 1000));
      } catch (e) {
        console.log(e);
        message.error((e as any)?.error?.message ?? "Сохранение поставщиков завершено с ошибками");
        await updateRecord('contragents', {...itemUpdated, error_action: true});
        await updateRecord('contragents__update', {...itemUpdated, error_action: true});
        hasErrorUpdated = true;
      }
    }

    for (const itemDeleted of itemsDeleted) {
      try {
        await directoryApi.contractors.contractorsDelete(itemDeleted.id ?? '');
        await new Promise((resolve) => setTimeout(resolve, 1000));
        await removeRecord('contragents', itemDeleted.id ?? '');
      } catch (e) {
        console.log(e);
        message.error((e as any)?.error?.message ?? "Сохранение поставщиков завершено с ошибками");
        await updateRecord('contragents', {...itemDeleted, error_action: true});
        await updateRecord('contragents__delete', {...itemDeleted, error_action: true});
        hasErrorDeleted = true;
      }
    }

    if (!hasErrorCreated && !hasErrorUpdated && !hasErrorDeleted) {
      message.success("Сохранение поставщиков завершено");
    }

    if (hasRemovedTimeLeft) {
      message.success("Отменены поставщики, которые не были сохранены в течение суток и имели ошибки.");
    }
  } catch (e) {
    console.log(e)
    message.error((e as any)?.error?.message ?? "Сохранение поставщиков завершено с ошибками");
  }
}

export async function getProjectsListDb(): Promise<Project[]> {
  if (!db) {
    console.log("База данных не открыта");
    return [];
  }

  return (await getAllRecords('projects_list')) ?? []
}

export async function getProjectsArchiveListDb(): Promise<Project[]> {
  if (!db) {
    console.log("База данных не открыта");
    return [];
  }

  return (await getAllRecords('projects_list_archive')) ?? []
}

export function updateProjectDetailDb(projectDetail: ProjectDetail) {
  if (!db) {
    console.log("База данных не открыта");
    return 0;
  }

  return updateRecord('project_detail', projectDetail);
}

export function updateProjectTableDb(projectTable: LoadData) {
  if (!db) {
    console.log("База данных не открыта");
    return 0;
  }

  return updateRecord('project_table', projectTable);
}

// update tableRow
export async function updateProjectTableRowDb(passportId: string, row: TableRecord) {
  if (!db) {
    console.log("База данных не открыта");
    return 0;
  }

  let projectTable = await getProjectTableDb(passportId);

  let rows: TableRecord[] = [];

  switch (row.cns_row_type) {
    case "section":
      rows = projectTable.rowsTable.rows;
      break;
    case "work_type":
      rows = projectTable.rowsTable.rows2;
      break;
    case "nomenclature":
      rows = projectTable.rowsTable.rows3;
      break;
  }

  const index = rows.findIndex(e => e.cns_id === row.cns_id);
  if (index > -1) {
    rows[index] = row;
  }

  return updateRecord('project_table', projectTable);
}

export function getProjectDetailDb(id: string): Promise<ProjectDetail> {
  if (!db) {
    console.log("База данных не открыта");
    return Promise.reject('База данных не открыта');
  }

  return getRecord('project_detail', id);
}

export function getProjectTableDb(id: string): Promise<LoadData> {
  if (!db) {
    console.log("База данных не открыта");
    return Promise.reject('База данных не открыта');
  }

  return getRecord('project_table', id);
}

export async function addProjectDocumentDb(id: string, type: DocumentType, item: IApiContragent2 | IApiInvoice2 | IApiWaybill2 | IApiBills2) {
  if (!db) {
    console.log("База данных не открыта");
    return;
  }

  return addRecord('project_documents__add', {
    id: id,
    type: type,
    item: {...item, created_at: moment().format('YYYY-MM-DDTHH:mm:ss')},
    passport_id: getPassportCache().id ?? '',
  })
}

export async function updateDocumentDb(rows: TableRecord[], passportId: string, itemId: string, type: DocumentType, item?: TableRecordContragent, isRemove?: boolean) {
  let fieldName: 'cns_contracts' | 'cns_invoices' | 'cns_way_bills' | 'cns_bills' = 'cns_contracts';
  switch (type) {
    case 'invoice':
      fieldName = 'cns_invoices';
      break;
    case 'waybill':
      fieldName = 'cns_way_bills';
      break;
    case 'bill':
      fieldName = 'cns_bills';
      break;
  }


  const rowsFiltered = rows.filter(row => row[fieldName].find(contract => contract.id == itemId));

  for (let row of rowsFiltered) {
    const index = row[fieldName].findIndex(contract => contract.id == itemId);
    if (isRemove) {
      row[fieldName].splice(index, 1);
    } else {
      if (item) {
        row[fieldName][index] = item;
      }
    }
  }

  let passport = await getProjectTableDb(passportId)

  for (let indexRow in passport.rowsTable.rows3) {
    let row = passport.rowsTable.rows3[indexRow];

    const indexFiltered = rowsFiltered.findIndex(e => e.cns_id == row.cns_id);
    if (indexFiltered > -1) {
      passport.rowsTable.rows3[indexRow] = {...row, [fieldName]: rowsFiltered[indexFiltered][fieldName]}
    }
  }

  await updateRecord('project_table', passport);

  if (isRemove) {
    await removeRecord('project_documents__add', itemId);
  }
}

export async function getDocumentsDb(id: string): Promise<DocumentDb[]> {
  if (!db) {
    console.log("База данных не открыта");
    return [];
  }

  return (await getAllRecords('project_documents__add'))?.filter((e: DocumentDb) => e.passport_id == id) ?? [];
}

export async function doRunDocuments() {
  try {
    if (!db) {
      console.log("База данных не открыта");
      return;
    }

    const itemsCreated = await db.getAll('project_documents__add');

    let hasErrorCreated = false;
    let hasErrorTimeLeft = false;

    for (let i = itemsCreated.length-1; i >= 0; i--) {
      const itemCreated = itemsCreated[i];
      if (itemCreated.error_action && moment().diff(moment(itemCreated.created_at), 'days') < 1) {
        await removeRecord('project_documents__add', itemCreated.id ?? '');
        itemsCreated.splice(i, 1);

        i--;
        hasErrorTimeLeft = true;
      }
    }

    if (hasErrorTimeLeft) {
      message.info("Отменено создание документов, созданных более суток назад и имеющих ошибки создания.");
    }

    if (itemsCreated.length == 0) {
      return;
    }

    for (const itemCreated of itemsCreated) {
      const passport = await getProjectTableDb(itemCreated.passport_id)

      try {
        let item2: IApiContragent2 | IApiInvoice2 = itemCreated.item;

        if (item2.files) {
          item2.file_ids = [];

          for (let indexDoc in item2.files ?? []) {
            let doc = item2.files![indexDoc];
            if ((doc as any).file_id?.includes('base64')) {
              const res = await documentsApi.files.uploadCreate({file: base642file(doc.id, doc.name!)});
              item2.file_ids.push(res.data.id ?? '')
            } else {
              if ((doc as any).file_id) {
                item2.file_ids.push((doc as any).file_id)
              }
            }
          }
        }

        await updateRecord('project_documents__add', {...itemCreated});

        item2.files = undefined;
        (item2 as any).contractor = undefined;

        if (itemCreated.type == 'contract') {
          await api.documents.contractsCreate(item2 as any);
        } else if (itemCreated.type == 'invoice') {
          await api.documents.invoicesCreate(item2 as any);
        } else if (itemCreated.type == 'waybill') {
          (item2 as any).contractor_id = undefined;
          await api.documents.waybillsCreate(item2 as any);
        } else if (itemCreated.type == 'bill') {
          (item2 as any).contractor_id = undefined;
          await api.documents.billsCreate(item2 as any);
        }


        await new Promise((resolve) => setTimeout(resolve, 1000));


        await updateDocumentDb(passport.rowsTable.rows3, itemCreated.passport_id, itemCreated.id ?? '', DocumentType.contract,undefined, true);
        await removeRecord('project_documents__add', itemCreated.id ?? '');

      } catch (e) {
        console.error(e);
        message.error((e as any)?.error?.message ?? "Сохранение документов завершено с ошибками");
        // await updateRecord('contragents', {...itemCreated, error_action: true});
        await updateRecord('project_documents__add', {...itemCreated, error_action: true});

        const passport = await getProjectTableDb(itemCreated.passport_id)
        const row = passport.rowsTable.rows3.find(row => row.cns_id == itemCreated.id)
        if (row) {
          let fieldName: 'cns_contracts' | 'cns_invoices' | 'cns_way_bills' | 'cns_bills' = 'cns_contracts';
          switch (itemCreated.type) {
            case 'invoice':
              fieldName = 'cns_invoices';
              break;
            case 'waybill':
              fieldName = 'cns_way_bills';
              break;
            case 'bill':
              fieldName = 'cns_bills';
              break;
          }

          const indexContract = row[fieldName].findIndex(contract => contract.id == itemCreated.id);
          row[fieldName][indexContract] = {...row[fieldName][indexContract], error_action: true};
          await updateProjectTableRowDb(getPassportCache().id ?? '', row);
        }

        hasErrorCreated = true;
      }
    }

    if (!hasErrorCreated) {
      message.success("Сохранение документов завершено");
    }
  } catch (e) {
    console.error(e)
    message.error((e as any)?.error?.message ?? "Сохранение документов завершено с ошибками");
  }
}


async function clearStore(storeName: keyof ContragentsDb) {
  if (!db) {
    console.log("База данных не открыта");
    return;
  }

  let transaction = db.transaction(storeName as any, "readwrite"); // (1)
  let store = transaction.objectStore(storeName); // (2)
  let request = await store.clear(); // (3)
  console.log("Записи удалены из хранилища", request);
}

async function removeRecord(storeName: keyof ContragentsDb, id: string) {
  if (!db) {
    console.log("База данных не открыта");
    return;
  }

  let transaction = db.transaction(storeName as any, "readwrite"); // (1)
  let store = transaction.objectStore(storeName); // (2)
  let request = await store.delete(id); // (3)
  console.log("Запись удалена из хранилища", request);
}

async function addRecord(storeName: keyof ContragentsDb, item: any) {
  if (!db) {
    console.log("База данных не открыта");
    return;
  }

  let transaction = db.transaction(storeName as any, "readwrite"); // (1)
  let store = transaction.objectStore(storeName); // (2)
  let request = await store.add(item); // (3)
  console.log("Запись добавлена в хранилище", request);
}

async function updateRecord(storeName: keyof ContragentsDb, item: any) {
  if (!db) {
    console.log("База данных не открыта");
    return;
  }

  let transaction = db.transaction(storeName as any, "readwrite"); // (1)
  let store = transaction.objectStore(storeName); // (2)
  let request = await store.put(item); // (3)
  // console.log("Запись обновлена в хранилище", request);
}

async function setRecord(storeName: keyof ContragentsDb, item: any) {
  if (!db) {
    console.log("База данных не открыта");
    return;
  }

  let transaction = db.transaction(storeName as any, "readwrite"); // (1)
  let store = transaction.objectStore(storeName); // (2)
  let request = await store.add(item); // (3)
  console.log("Запись добавлена в хранилище", request);
}

async function getRecord(storeName: keyof ContragentsDb, id: string) {
  if (!db) {
    console.log("База данных не открыта");
    return;
  }

  let transaction = db.transaction(storeName as any, "readonly"); // (1)
  let store = transaction.objectStore(storeName); // (2)
  return await store.get(id); // (3)
}

async function getAllRecords(storeName: keyof ContragentsDb) {
  if (!db) {
    console.log("База данных не открыта");
    return;
  }

  let transaction = db.transaction(storeName as any, "readonly"); // (1)
  let store = transaction.objectStore(storeName); // (2)
  return await store.getAll(); // (3)
}

export let isOnline = process.env.REACT_APP_DEBUG_SYNC_IS_ONLINE !== undefined ? process.env.REACT_APP_DEBUG_SYNC_IS_ONLINE == 'true' : navigator.onLine;
